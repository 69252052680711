import * as R from 'ramda'
import * as D from 'date-fns'
import { useState, useEffect } from 'react'

import s from './styles.module.css'

const REGEX = /(0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])[- \/.](19|20)\d\d/
const REGEX_INPUT = /(\d{0,2})\/?(\d{0,2})\/?(\d{0,4})/

const parseDate = dateString => new Date(dateString)
const stringifyDate = date => D.format(date, 'MM/DD/YYYY')
const parseDateParts = dateString => {
  const [_, month, day, year] = R.match(REGEX_INPUT, dateString)
  const formattedMonth = month ? Math.min(month, 12) : undefined
  const formattedDay = day ? Math.min(day, 31) : undefined
  return {
    month:
      !R.isNil(formattedMonth) &&
      `${
        R.length(month) === 2 && formattedMonth < 10 ? '0' : ''
      }${formattedMonth}`,
    day:
      !R.isNil(formattedDay) &&
      `${R.length(day) === 2 && formattedDay < 10 ? '0' : ''}${formattedDay}`,
    year:
      year && R.length(year) === 4
        ? `${Math.min(Math.max(year, 1900), new Date().getFullYear())}`
        : `${year}`,
  }
}
const getStringRepresentationOfDate = dateString => {
  const { month, day, year } = parseDateParts(dateString)
  return (
    `${month || ''}${R.length(month) === 2 ? '/' : ''}` +
    `${day || ''}${R.length(day) === 2 ? '/' : ''}${year || ''}`
  )
}

const getError = stringValue => {
  return R.test(REGEX, stringValue)
    ? null
    : 'Use the format MM/DD/YYYY. ex: 06/01/1986'
}

const DateInput = ({
  value: initialValue,
  onBlur: propOnBlur,
  onChangeDetailed = () => {},
  placeholder = '',
}) => {
  const [value, setValue] = useState(initialValue || null)
  const [stringValue, setStringValue] = useState(
    value ? stringifyDate(value) : ''
  )
  const [error, setError] = useState(null)

  useEffect(() => {
    setValue(value)
  }, [value])

  const onBlur = () => {
    const error = getError(stringValue)
    if (error) {
      return setError(error)
    }

    const newValue = parseDate(stringValue)
    setValue(newValue)
    propOnBlur && propOnBlur(newValue)
  }

  const onChange = ev => {
    const newStringValue = ev.target.value

    if (
      R.length(newStringValue) < R.length(stringValue) &&
      (R.last(newStringValue) === '/' || R.last(stringValue) === '/')
    ) {
      setStringValue(newStringValue.substring(0, R.length(newStringValue) - 1))
    } else {
      setStringValue(getStringRepresentationOfDate(newStringValue))
    }

    const error = getError(newStringValue)
    setError(error)
    onChangeDetailed({
      value: parseDate(newStringValue),
      stringValue: newStringValue,
      isValid: !error,
      error,
    })
  }

  const onKeyDown = ev => {
    if (ev.key === 'ArrowLeft' || ev.key === 'ArrowRight') {
      ev.stopPropagation()
    }
  }

  return (
    <div className={s.container}>
      <input
        className={s.input}
        type="text"
        value={stringValue}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        placeholder={`${placeholder} MM/DD/YYYY`}
        maxLength={10}
      />
      {error && <div className={s.error}>{error}</div>}
    </div>
  )
}

export default DateInput
