import s from './styles.module.css'

const SectionTitle = ({ title, subtitle, className, showBorder = true }) => {
  return (
    <div className={className}>
      <span className={s.subtitle}>{subtitle}</span>
      <h3 className={s.title}>{title}</h3>
      {showBorder && <div className={s.border}></div>}
    </div>
  )
}

export default SectionTitle
