import { cloneElement, isValidElement, ReactElement, ReactNode } from 'react'
import * as R from 'ramda'

// From ChatGPT
export const shuffleArray = (arr: any[]) => {
  const newArr = [...arr] // Create a copy of the array
  for (let i = newArr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[newArr[i], newArr[j]] = [newArr[j], newArr[i]] // Swap elements
  }
  return newArr
}

export const indexBy = R.curryN(2, (indexFn, list) => {
  return R.map(R.head, R.groupBy(indexFn, list))
})

export const mapWithMetadata = R.curryN(2, (fn, items) => {
  return R.addIndex(R.map)((item, index) => {
    return fn(item, {
      index,
      isFirst: index === 0,
      isLast: index === items.length - 1,
      isEven: index % 2 === 0,
    })
  }, items)
})

export const mapWithSeparator = <T>(
  source: Iterable<T>,
  mapfn: (x: T) => ReactNode,
  separator: ReactNode
) => {
  const result: ReactNode[] = []

  const iter = source[Symbol.iterator]()

  let { value: current, done } = iter.next()
  if (done) return result

  let last = current
  ;({ value: current, done } = iter.next())

  let i = isValidElement(separator) ? 0 : undefined
  while (!done) {
    result.push(mapfn(last))
    if (i !== undefined)
      result.push(
        cloneElement(separator as ReactElement, { key: 'sep_' + i++ })
      )
    else result.push(separator)
    last = current
    ;({ value: current, done } = iter.next())
  }
  result.push(mapfn(last))
  return result
}

export const getRandomElement = <T>(list: T[]): T => {
  return list[Math.floor(Math.random() * list.length)]
}
