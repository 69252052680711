import s from './styles.module.css'
import { getImage } from 'clients/cloudinary'
import Image from 'next/image'
import { useEffect, useState } from 'react'

const getImageUrl = (fileName: string) => {
  return getImage(`static/${fileName}.jpeg`).toURL()
}

const processTemplate = (
  template: string,
  primaryGoalText?: string
): React.ReactNode => {
  if (!template) return ''

  const textWithReplacedVars = template.replace(
    /{{primaryGoalText}}/g,
    primaryGoalText || ''
  )

  const parts = textWithReplacedVars.split(/({{g}}.*?{{\/g}})/)

  return parts.map((part, index) => {
    const greenTextMatch = part.match(/{{g}}(.*?){{\/g}}/)

    if (greenTextMatch) {
      return (
        <span key={index} className={s.greenBackground}>
          <span key={index} className={s.greenText}>
            {greenTextMatch[1]}
          </span>
        </span>
      )
    }

    return part
  })
}

export default function PrimaryGoalInterstitial(props) {
  const { question, responseBySurveyQuestionId } = props
  const { uploadWidgetOptions } = question || {}
  const primaryGoal = responseBySurveyQuestionId?.[400]
  const responseId = primaryGoal?.surveyQuestionResponseId
  const primaryGoalType =
    uploadWidgetOptions?.[400]?.[responseId ? parseInt(responseId, 10) : 268]
  const [imageSrc, setImageSrc] = useState('Visual_from_Figma_lp65jq')

  useEffect(() => {
    const handleResize = () => {
      const isLargerThanTablet = window.innerWidth >= 768
      setImageSrc(
        isLargerThanTablet
          ? 'Holding_Frame_2_sxcvim'
          : 'Visual_from_Figma_lp65jq'
      )
    }

    handleResize() // Initial check
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <div className={s.container}>
      <h2 className={s.header}>
        {processTemplate(question?.text, primaryGoalType?.primaryGoalText)}
      </h2>
      <div className={s.imageContainer}>
        <div className={s.imageTitle}>
          <span className={s.imageTitleText}>
            {primaryGoalType?.imageTitle || 'Health and Fitness'}
          </span>
        </div>
        <Image
          alt={primaryGoalType?.imageTitle || 'Health and Fitness'}
          src={getImageUrl(imageSrc)}
          className={s.weightLoss}
          width={500}
          height={300}
        />
        <span className={s.imageCaption}>
          Based on a study of 26,000 Kickoff users and a Harris Interactive
          Study of average goal achievement.
        </span>
      </div>
    </div>
  )
}
