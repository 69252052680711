import { getImage } from 'clients/cloudinary'
import s from './styles.module.css'
import TextBubble from './text-bubble'
import { fill } from '@cloudinary/url-gen/actions/resize'
import { focusOn } from '@cloudinary/url-gen/qualifiers/gravity'
import { FocusOn } from '@cloudinary/url-gen/qualifiers/focusOn'
import Image from 'next/image'

const genderIds = {
  13: 'male',
  12: 'female',
  56: 'other',
}

const generateImageUrl = (fileName: string) => {
  return getImage(`static/${fileName}.jpeg`)
    .resize(fill().width(500).height(500).gravity(focusOn(FocusOn.face())))
    .toURL()
}

const getCoachImage = (clientGender: string, clientAge: number) => {
  if (!clientGender || !clientAge) {
    return generateImageUrl('User_Photo_1_zt6itq')
  }

  if (clientGender === 'male' && clientAge < 40) {
    return generateImageUrl('Young_Male_Coach_zqt4i6')
  } else if (clientGender === 'male' && clientAge >= 40) {
    return generateImageUrl('Old_Male_Coach_zex91g')
  } else if (clientGender !== 'male' && clientAge < 40) {
    return generateImageUrl('User_Photo_1_zt6itq')
  } else if (clientGender !== 'male' && clientAge >= 40) {
    return generateImageUrl('Female_Old_Coach_1_i5pe9z')
  }

  return generateImageUrl('User_Photo_1_zt6itq')
}

const processTemplate = (
  template: string,
  fitnessChallengeText?: string
): React.ReactNode => {
  if (!template) return ''

  const textWithReplacedVars = template.replace(
    /{{fitnessChallengeText}}/g,
    `###${fitnessChallengeText || ''}###`
  )

  const parts = textWithReplacedVars.split(/({{g}}.*?{{\/g}})|(###.*?###)/)

  return parts.map((part, index) => {
    if (!part) return null

    const greenTextMatch = part.match(/{{g}}(.*?){{\/g}}/)
    const fitnessTextMatch = part.match(/###(.*?)###/)

    if (greenTextMatch) {
      return (
        <span key={index} className={s.greenBackground}>
          <span key={index} className={s.greenText}>
            {greenTextMatch[1]}
          </span>
        </span>
      )
    }

    if (fitnessTextMatch) {
      return (
        <span key={index} className={s.fitnessChallengeText}>
          {fitnessTextMatch[1]}
        </span>
      )
    }

    return part
  })
}

export default function FitnessChallengeInterstitial(props) {
  const { question, responseBySurveyQuestionId } = props
  const { uploadWidgetOptions } = question || {}
  const fitnessChallenge = responseBySurveyQuestionId?.[214]
  const responseId = fitnessChallenge?.surveyQuestionResponseId
  const fitnessChallengeType =
    uploadWidgetOptions?.[214]?.[responseId ? parseInt(responseId, 10) : 1]
  const clientAgeStr = responseBySurveyQuestionId?.[11]?.responseValue
  const clientGenderId =
    responseBySurveyQuestionId?.[13]?.surveyQuestionResponseId
  const clientGender =
    genderIds[clientGenderId ? parseInt(clientGenderId, 10) : 56]
  const clientAge = clientAgeStr
    ? new Date().getFullYear() -
      new Date(parseInt(clientAgeStr, 10)).getFullYear()
    : null
  const coachImage = getCoachImage(clientGender, clientAge)

  return (
    <div className={s.fitnessChallengeInterstitial}>
      <div className={s.headerContainer}>
        <span className={s.overline}>THE HUMAN DIFFERENCE</span>
        <h2 className={s.header}>
          {processTemplate(
            question?.text,
            fitnessChallengeType?.fitnessChallengeText ||
              'overcoming challenges'
          )}
        </h2>
        <div className={s.noteContainer}>
          <span>{question?.note}</span>
        </div>
      </div>
      <div className={s.coachContainer}>
        <Image
          alt="coach"
          src={coachImage}
          className={s.coach}
          width={100}
          height={100}
        />
        <div className={s.bubbleContainer}>
          <TextBubble
            text={
              fitnessChallengeType?.callout ||
              'We’ll build a fitness plan that’s personal to you'
            }
          />
        </div>
      </div>
    </div>
  )
}
